/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

.site-content {
  flex: 1;
  color: #ffffff;
  font-size: 75px;
  font-family: "Ubuntu", sans-serif;
}

#body {
  background: rgb(63, 94, 251);
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(10, 9, 9, 1) 100%
  );
}

.title {
  font-size: 75px;
  font-family: "Ubuntu", sans-serif;
}

#foot {
  background-color: #f5f5f5;
}

.fa-laptop-code {
  color: #3273dc;
}

#lastname {
  color: #3273dc;
}

// .a {
//   animation-duration: 1s;
//   animation-fill-mode: both;
//   animation-iteration-count: 1;
//   position: absolute;
// }

// .a:hover {
//   color: orange;
//   /* animation: rubberBand 5s infinite; */
//   animation-name: rubberBand;
// }

// @keyframes rubberBand {
//   from {
//     transform: scale3d(1, 1, 1);
//   }

//   30% {
//     transform: scale3d(1.25, 0.75, 1);
//   }

//   40% {
//     transform: scale3d(0.75, 1.25, 1);
//   }

//   50% {
//     transform: scale3d(1.15, 0.85, 1);
//   }

//   65% {
//     transform: scale3d(0.95, 1.05, 1);
//   }

//   75% {
//     transform: scale3d(1.05, 0.95, 1);
//   }

//   to {
//     transform: scale3d(1, 1, 1);
//   }
// }
